import $ from 'jquery';
import slick from 'slick-carousel';
window.jQuery = $;

export default {
  init() {
    // JavaScript to be fired on all pages

    // Navigation Toggle
    $('#nav-toggle').click(e => {
      e.preventDefault();
      $('#header').toggleClass('is-active');
    });

    //Custom Menu Closing
    $('a[href="#map"], a[href="#hours"]').click(() =>  {
      $('#header').removeClass('is-active');
    });

    // Testimonials Slider
    $('.module-testimonials > .testimonials').slick({
      dots: true,
      arrows: false,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 8000,
      fade: true,
      speed: 250,
      pauseOnHover: false
    });

    // Booking: Trigger Modal
    const bookingTriggers = $('a[href="#booking"], a[href="#piercing"], a[href="#tattoo"], a[href="#jewelry"]');
    const bookingTarget = $('#modal-booking');
    bookingTriggers.click(e => {
      e.preventDefault();
      bookingTarget.addClass('is-active');
      const bookingServiceChosen = $(e.currentTarget).attr('href').replace('#', '');
      $(`#book-${bookingServiceChosen}`).trigger('click');
    });
    $('.modal-close, .modal-underlay').click(e => {
      e.preventDefault();
      bookingTarget.removeClass('is-active');
    });

    // Booking: Select a service
    let bookingService = '';
    let bookingAgeMin = 0;
    const bookingAgeSelect = $('#booking-ageSelect');
    const bookingModalHeader = $('#booking-header');
    $('a.booking-service-choice').on('click', e => {
      e.preventDefault();
      $('.booking-service-choice, #booking-idChoices a, .booking-ageRestriction').removeClass('is-active');
      $('.module-booking').removeClass('is-ready');
      const bookingChosen = bookingService = $(e.currentTarget).data('service');
      $('.booking-service > p, .booking-idQuestion').addClass('is-complete');
      const bookingAgeChosen = $(`#booking-age-${bookingChosen}`)
      bookingAgeMin = bookingAgeChosen.data('age-min');
      bookingAgeChosen.addClass('is-active');
      const bookingNamePast = $(e.currentTarget).data('service-past');
      bookingModalHeader.removeClass('booking-piercing booking-jewelry booking-tattoo').addClass(`booking-${bookingChosen}`)
      bookingAgeSelect.parent('.booking-select').attr('data-service', bookingService);
      $(e.currentTarget).addClass('is-active');
      $('#book-ageService').text(bookingNamePast.toLowerCase());
      $('.booking-age').slideDown();
      $('#booking-idLink, #booking-idChoices').attr('data-service', bookingService);
      $('.booking-id, .booking-idQuestion, #booking-idFail, .booking-protocol').slideUp();
      $(`.booking-ageRestriction`).removeClass('is-emphasis');
      $('.booking-ageRestriction, .booking-age p').removeClass('is-complete');
      bookingAgeSelect.val('choose');
    });

    // Booking: Age Selection
    bookingAgeSelect.change(e => {
      if(bookingAgeMin === 0) {
        $(`#booking-protocol-${bookingService}`).slideDown();
        const booking3rdPartyLink = $(`#booking-protocol-${bookingService} a.booking-protocol-link`);
        if(booking3rdPartyLink.length) {
          const booking3rdPartyUrl = booking3rdPartyLink.attr('href');
          setTimeout(() => {
            window.open(booking3rdPartyUrl, '_blank').focus();
          }, 3000);
        }
      } else {
        const bookingAge = $(e.currentTarget).val();
        $('.booking-protocol, #booking-idFail').slideUp();
        $('#booking-idChoices a').removeClass('is-active');
        $('.booking-idQuestion').removeClass('is-complete');
        $('.module-booking').removeClass('is-ready');
        if(bookingAge < 18 || bookingAge < bookingAgeMin) {
          $(`.booking-ageRestriction`).addClass('is-emphasis');
          $('.booking-idQuestion-minors, .booking-idQuestion').slideUp();
        }
        if(bookingAge >= bookingAgeMin) {
          $('.booking-id').slideDown();
          $('.booking-age > p').addClass('is-complete');
          $('.booking-ageRestriction').removeClass('is-emphasis');
          $('.booking-idQuestion').slideUp();
          if(bookingAge < 18) {
            $(`#booking-idQuestion-minor-${bookingService}`).slideDown();
          } else {
            $(`#booking-idQuestion-${bookingService}`).slideDown();
          }
        } else {
          $('.booking-age > p').removeClass('is-complete');
          $('.booking-id').slideUp();
        }
      }
    });

    // Booking ID Confirmation
    $('#booking-idChoices a').on('click', e => {
      e.preventDefault();
      const bookingIdBring = $(e.currentTarget).data('id');
      $('#booking-idChoices a').removeClass('is-active');
      $(e.currentTarget).addClass('is-active');
      if(bookingIdBring) {
        $('.booking-idQuestion').addClass('is-complete');
        $('#booking-idFail').slideUp();
        $('.module-booking').addClass('is-ready');
        $(`#booking-protocol-${bookingService}`).slideDown();
        const booking3rdPartyLink = $(`#booking-protocol-${bookingService} a.booking-protocol-link`);
        if(booking3rdPartyLink.length) {
          const booking3rdPartyUrl = booking3rdPartyLink.attr('href');
          setTimeout(() => {
            window.open(booking3rdPartyUrl, '_blank').focus();
          }, 3000);
        }
      } else {
        $('.booking-idQuestion').removeClass('is-complete');
        $('#booking-idFail').slideDown();
        $('.module-booking').removeClass('is-ready');
        $('.booking-protocol').slideUp();
      }
    });

  },
  finalize() {
    // Get the window size & make it dynamic for resizing
    let windowSize = $(window).width();
    $(window).on('resize', () => {
      windowSize = $(window).width();
    });

    // Services Staff listing
    $('.bio-toggle').click(e => {
      e.preventDefault();
      $(e.currentTarget).parent().next('.staff-bio').slideToggle();
      $(e.currentTarget).toggleClass('is-active');
    });

    // Help Nav Open
    let helpNavState = false;
    $('#help-nav a').click(e => {
      e.preventDefault();
      if(helpNavState || windowSize >= 1248) {
        $('.help-filter').removeClass('is-active')
        $('#help-nav').removeClass('is-active');
        $(e.currentTarget).parent().addClass('is-active');
        helpNavState = false;

        // Display all related help articles
        const helpTopic = $(e.currentTarget).data('topic');
        let helpArticleFoundFirst = true;
        $('.module-helpArticle').each((i,el) => {
          $(el).removeClass('is-visible is-active');
          const helpArticleTopics = $(el).data('topics');
          if(helpArticleTopics.includes(helpTopic)) {
            $(el).addClass('is-visible');
            if(helpArticleFoundFirst) {
              $(el).addClass('is-active');
              helpArticleFoundFirst = false;
            }
          } else if(helpTopic === 'everything') {
            $('.module-helpArticle').addClass('is-visible');
            if(helpArticleFoundFirst) {
              $(el).addClass('is-active');
              helpArticleFoundFirst = false;
            }
          }
        });

      } else {
        $('#help-nav').addClass('is-active');
        helpNavState = true;
      }
    });

    // Help Article Open
    $('.module-helpArticle header').click(e => {
      e.preventDefault();
      if($(e.currentTarget).parent().hasClass('is-active')) {
        $(e.currentTarget).parent().removeClass('is-active');
      } else {
        $(e.currentTarget).parent().addClass('is-active');
      }
    });

    // Help Article Activate after Single Redirect
    const urlCurrentParams = new URLSearchParams(window.location.search);
    const helpArticleFound = urlCurrentParams.get('article');
    let helpArticleTopic = '';
    if(helpArticleFound) {
      const helpArticles = $('.module-helpArticle');
      const helpArticle = $(`#${helpArticleFound}`);
      helpArticles.removeClass('is-active');
      const helpArticleTopics = helpArticle.data('topics');
      if(helpArticleTopics) {
        $('.help-filter').removeClass('is-active');
        if(helpArticleTopics.includes(' ')) {
          helpArticleTopic = helpArticleTopics.substring(0, helpArticleTopics.indexOf(' '));
        } else {
          helpArticleTopic = helpArticleTopics;
        }
        $(`.help-filter a[data-topic="${helpArticleTopic}"]`).parent().addClass('is-active');
        helpArticles.removeClass('is-visible');
        helpArticles.each((i,art) => {
          if($(art).data('topics').includes(helpArticleTopic)) {
            $(art).addClass('is-visible');
          }
        });
      }
      // Scroll to the help article after loading
      $('html, body').animate({
        scrollTop: helpArticle.offset().top - 144
      }, 0, function() {
        helpArticle.addClass('is-active');
      });
    }
  },
};
